<template>
  <div id="booking-policy-form">
    <!-- DESCRIPTION -->
    <b-row class="mb-2">
      <b-col cols="12" class="mb-1">
        <h5>Descripció</h5>
      </b-col>
      <b-col cols="12">
        <span>{{ policy.description }}</span>
      </b-col>
    </b-row>

    <b-row class="mb-1">
      <b-col cols="12" class="mb-1">
        <h5>Condicions</h5>
      </b-col>
      <b-col cols="12">
        <b-alert variant="dark" show>
          <div class="alert-body">
            <booking-policy-preview :policy="policy" />
          </div>
        </b-alert>
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="mb-1 d-flex justify-content-between justify-content-sm-end">
      <b-col cols="12" sm="auto">
        <b-button block variant="primary" @click="close()"> Tanca </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BAlert, BButton } from "bootstrap-vue";
import BookingPolicyPreview from "@/views/booking-policies/list/components/BookingPolicyPreview.vue";

export default {
  name: "BookingPolicyForm",
  components: {
    BRow,
    BCol,
    BAlert,
    BookingPolicyPreview,
    BButton,
  },
  props: {
    policy: {
      type: Object,
      required: false,
      default: null,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
