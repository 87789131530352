<template>
  <div id="booking-policy-form">
    <!-- NAME -->
    <b-row class="mb-2">
      <b-col cols="12">
        <h6>Nom</h6>
      </b-col>
      <b-col cols="12">
        <b-form-input v-model="newPolicy.name" placeholder="Flexible" />
      </b-col>
    </b-row>

    <!-- DESCRIPTION -->
    <b-row class="mb-2">
      <b-col cols="12">
        <h6>Descripció</h6>
      </b-col>
      <b-col cols="12">
        <b-form-input
          v-model="newPolicy.description"
          placeholder="Cancel·lació gratuïta fins a 48h"
        />
      </b-col>
    </b-row>

    <!-- DEFAULT POLICY -->
    <b-row>
      <b-col cols="12">
        <h6>Ha de ser política base?</h6>
      </b-col>
      <b-col cols="12">
        <b-form-checkbox v-model="newPolicy.defaultPolicy" switch />
      </b-col>
    </b-row>

    <!-- CONFIG TABS -->
    <b-row class="my-3">
      <!-- TABS -->
      <b-col cols="12">
        <b-tabs pills justified>
          <!-- PAYMENTS -->
          <b-tab title="Pagament" active>
            <!-- INITIAL PAYMENT AMOUNT -->
            <b-row class="mb-2">
              <b-col cols="12">
                <h6>Pagament al reservar</h6>
              </b-col>
              <b-col cols="12">
                <b-input-group append="%" class="input-group-merge">
                  <b-form-input
                    v-model="newPolicy.initialPaymentAmount"
                    type="number"
                    placeholder="30"
                  />
                </b-input-group>
              </b-col>
            </b-row>

            <!-- FINAL PAYMENT LIMIT -->
            <b-row>
              <b-col cols="12">
                <h6>Límit pagament final</h6>
              </b-col>
              <b-col cols="12">
                <b-input-group>
                  <b-form-input
                    v-model="newPolicy.finalPaymentLimitInput"
                    type="number"
                    placeholder="21"
                  />
                  <b-input-group-append is-text>
                    <b-form-radio-group
                      v-model="newPolicy.finalPaymentLimitUnitInput"
                      :options="daysHoursOptions"
                      value-field="value"
                      text-field="text"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>
          </b-tab>

          <!-- CANCELLATIONS -->
          <b-tab title="Cancel·lació">
            <!-- FREE CANCELLATION GRACE PERIOD -->
            <b-row class="mb-2">
              <b-col cols="12">
                <h6>Període cancel·lació gratuïta després de reservar</h6>
              </b-col>
              <b-col cols="12">
                <b-input-group>
                  <b-form-input
                    v-model="newPolicy.freeCancellationGracePeriodInput"
                    type="number"
                    placeholder="48"
                    :disabled="!isEmpty(newPolicy.freeCancellationLimitInput)"
                  />
                  <b-input-group-append is-text>
                    <b-form-radio-group
                      v-model="newPolicy.freeCancellationGracePeriodUnitInput"
                      :options="daysHoursOptions"
                      value-field="value"
                      text-field="text"
                      :disabled="!isEmpty(newPolicy.freeCancellationLimitInput)"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>

            <!-- FREE CANCELLATION LIMIT -->
            <b-row class="mb-2">
              <b-col cols="12">
                <h6>Limit cancel·lació gratuïta</h6>
              </b-col>
              <b-col cols="12">
                <b-input-group>
                  <b-form-input
                    v-model="newPolicy.freeCancellationLimitInput"
                    type="number"
                    placeholder="14"
                    :disabled="
                      !isEmpty(newPolicy.freeCancellationGracePeriodInput)
                    "
                  />
                  <b-input-group-append is-text>
                    <b-form-radio-group
                      v-model="newPolicy.freeCancellationLimitUnitInput"
                      :options="daysHoursOptions"
                      value-field="value"
                      text-field="text"
                      :disabled="
                        !isEmpty(newPolicy.freeCancellationGracePeriodInput)
                      "
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>

            <!-- PARTIAL REFUND LIMIT -->
            <b-row class="mb-2">
              <b-col cols="12">
                <h6>Limit devolució parcial</h6>
              </b-col>
              <b-col cols="12">
                <b-input-group>
                  <b-form-input
                    v-model="newPolicy.partialRefundLimitInput"
                    type="number"
                    placeholder="7"
                  />
                  <b-input-group-append is-text>
                    <b-form-radio-group
                      v-model="newPolicy.partialRefundLimitUnitInput"
                      :options="daysHoursOptions"
                      value-field="value"
                      text-field="text"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>

            <!-- PARTIAL REFUND AMOUNT -->
            <b-row class="mb-2">
              <b-col cols="12">
                <h6>Devolució parcial</h6>
              </b-col>
              <b-col cols="12">
                <b-input-group append="%" class="input-group-merge">
                  <b-form-input
                    v-model="newPolicy.partialRefundAmount"
                    type="number"
                    placeholder="50"
                  />
                </b-input-group>
              </b-col>
            </b-row>

            <!-- REFUNDABLE SERVICES -->
            <b-row>
              <b-col cols="12">
                <h6>Devolució de serveis?</h6>
              </b-col>
              <b-col cols="12">
                <b-form-checkbox
                  v-model="newPolicy.refundableServices"
                  switch
                />
              </b-col>
            </b-row>
          </b-tab>

          <!-- LAST MINUTE -->
          <b-tab title="Last minute">
            <!-- LAST MINUTE START -->
            <b-row class="mb-2">
              <b-col cols="12">
                <h6>Inici del "last minute"</h6>
              </b-col>
              <b-col cols="12">
                <b-input-group>
                  <b-form-input
                    v-model="newPolicy.lastMinuteStartInput"
                    type="number"
                    placeholder="7"
                  />
                  <b-input-group-append is-text>
                    <b-form-radio-group
                      v-model="newPolicy.lastMinuteStartUnitInput"
                      :options="daysHoursOptions"
                      value-field="value"
                      text-field="text"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>

            <!-- LAST MINUTE FREE CANCELLATION LIMIT -->
            <b-row class="mb-2">
              <b-col cols="12">
                <h6>Limit cancel·lació gratuïta</h6>
              </b-col>
              <b-col cols="12">
                <b-input-group>
                  <b-form-input
                    v-model="newPolicy.lastMinuteFreeCancellationLimitInput"
                    type="number"
                    placeholder="3"
                  />
                  <b-input-group-append is-text>
                    <b-form-radio-group
                      v-model="
                        newPolicy.lastMinuteFreeCancellationLimitUnitInput
                      "
                      :options="daysHoursOptions"
                      value-field="value"
                      text-field="text"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>

            <!-- REFUNDABLE SERVICES -->
            <b-row>
              <b-col cols="12">
                <h6>Devolució de serveis "last minute"?</h6>
              </b-col>
              <b-col cols="12">
                <b-form-checkbox
                  v-model="newPolicy.lastMinuteRefundableServices"
                  switch
                  :disabled="
                    !newPolicy.lastMinuteStartInput &&
                    !newPolicy.lastMinuteFreeCancellationLimitInput
                  "
                />
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-col>

      <!-- ALERTS -->
      <b-col cols="12">
        <b-alert
          variant="danger"
          :show="inconsistentFinalPaymentLimit"
          class="mt-2"
        >
          <h4 class="alert-heading">Alerta!</h4>
          <div class="alert-body">
            <p class="mb-1">
              L'import a tornar en cas de cancel·lació serà més gran que el
              cobrat fins el moment. Per corregir-ho, realitza una de les
              següents accions:
            </p>
            <ul>
              <li>Disminueix el límit de devolució parcial</li>
              <li>Disminueix el % de devolució parcial</li>
              <li>Augmenta el % de pagament al reservar</li>
              <li>Augmenta el límit de pagament final</li>
            </ul>
          </div>
        </b-alert>
        <b-alert
          variant="danger"
          :show="inconsistentPartialRefundLimit"
          class="mt-2"
        >
          <h4 class="alert-heading">Alerta!</h4>
          <div class="alert-body">
            <p>
              El límit de devolució parcial ha de ser inferior al de
              cancel·lació gratuïta.
            </p>
          </div>
        </b-alert>
        <b-alert
          variant="danger"
          :show="inconsistentLastMinuteFreeCancellationLimit"
          class="mt-2"
        >
          <h4 class="alert-heading">Alerta!</h4>
          <div class="alert-body">
            <p>
              El límit de cancel·lació gratuïta "last minute" ha de ser inferior
              a l'inici del període "last minute".
            </p>
          </div>
        </b-alert>
      </b-col>
    </b-row>

    <!-- PREVIEW -->
    <b-row v-if="newPolicy.name">
      <b-col cols="12">
        <b-alert variant="dark" show>
          <h4 class="alert-heading">
            Política de reserva {{ newPolicy.name }}
          </h4>
          <div class="alert-body">
            <booking-policy-preview :policy="newPolicyPreview" />
          </div>
        </b-alert>
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="my-1 d-flex justify-content-between justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="cancel()"> Cancel·la </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button block variant="primary" @click="save()"> Guarda </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormInput,
  BFormCheckbox,
  BTabs,
  BTab,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  BAlert,
} from "bootstrap-vue";
import { isEmpty } from "@/utils/methods";
import BookingPolicyPreview from "@/views/booking-policies/list/components/BookingPolicyPreview.vue";

export default {
  name: "BookingPolicyForm",
  components: {
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormCheckbox,
    BTabs,
    BTab,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    BAlert,
    BookingPolicyPreview,
  },
  props: {
    policy: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      newPolicy: {
        name: null,
        description: null,
        defaultPolicy: false,
        freeCancellationGracePeriodInput: null,
        freeCancellationGracePeriodUnitInput: "hours",
        freeCancellationLimitInput: null,
        freeCancellationLimitUnitInput: "days",
        partialRefundLimitInput: null,
        partialRefundLimitUnitInput: "days",
        partialRefundAmount: null,
        refundableServices: true,
        initialPaymentAmount: null,
        finalPaymentLimitInput: null,
        finalPaymentLimitUnitInput: "days",
        lastMinuteStartInput: null,
        lastMinuteStartUnitInput: "days",
        lastMinuteFreeCancellationLimitInput: null,
        lastMinuteFreeCancellationLimitUnitInput: "days",
        lastMinuteRefundableServices: true,
      },
      daysHoursOptions: [
        { text: "hores", value: "hours", disabled: false },
        { text: "dies", value: "days", disabled: false },
      ],
    };
  },
  computed: {
    freeCancellationGracePeriod() {
      if (
        !this.newPolicy.freeCancellationGracePeriodInput ||
        this.newPolicy.freeCancellationGracePeriodInput === 0
      )
        return null;
      return this.newPolicy.freeCancellationGracePeriodUnitInput === "days"
        ? this.newPolicy.freeCancellationGracePeriodInput * 24
        : this.newPolicy.freeCancellationGracePeriodInput * 1;
    },
    freeCancellationLimit() {
      if (
        !this.newPolicy.freeCancellationLimitInput ||
        this.newPolicy.freeCancellationLimitInput === 0
      )
        return null;
      return this.newPolicy.freeCancellationLimitUnitInput === "days"
        ? this.newPolicy.freeCancellationLimitInput * 24
        : this.newPolicy.freeCancellationLimitInput * 1;
    },
    partialRefundLimit() {
      if (
        !this.newPolicy.partialRefundLimitInput ||
        this.newPolicy.partialRefundLimitInput === 0
      )
        return null;
      return this.newPolicy.partialRefundLimitUnitInput === "days"
        ? this.newPolicy.partialRefundLimitInput * 24
        : this.newPolicy.partialRefundLimitInput * 1;
    },
    finalPaymentLimit() {
      if (
        !this.newPolicy.finalPaymentLimitInput ||
        this.newPolicy.finalPaymentLimitInput === 0
      )
        return null;
      return this.newPolicy.finalPaymentLimitUnitInput === "days"
        ? this.newPolicy.finalPaymentLimitInput * 24
        : this.newPolicy.finalPaymentLimitInput * 1;
    },
    lastMinuteStart() {
      if (
        !this.newPolicy.lastMinuteStartInput ||
        this.newPolicy.lastMinuteStartInput === 0
      )
        return null;
      return this.newPolicy.lastMinuteStartUnitInput === "days"
        ? this.newPolicy.lastMinuteStartInput * 24
        : this.newPolicy.lastMinuteStartInput * 1;
    },
    lastMinuteFreeCancellationLimit() {
      if (
        !this.newPolicy.lastMinuteFreeCancellationLimitInput ||
        this.newPolicy.lastMinuteFreeCancellationLimitInput === 0
      )
        return null;
      return this.newPolicy.lastMinuteFreeCancellationLimitUnitInput === "days"
        ? this.newPolicy.lastMinuteFreeCancellationLimitInput * 24
        : this.newPolicy.lastMinuteFreeCancellationLimitInput * 1;
    },
    inconsistentPartialRefundLimit() {
      if (
        isEmpty(this.newPolicy.freeCancellationLimitInput) ||
        isEmpty(this.newPolicy.partialRefundLimitInput)
      ) {
        return false;
      }
      return this.partialRefundLimit >= this.freeCancellationLimit;
    },
    inconsistentLastMinuteFreeCancellationLimit() {
      if (
        isEmpty(this.newPolicy.lastMinuteStartInput) ||
        isEmpty(this.newPolicy.lastMinuteFreeCancellationLimitInput)
      ) {
        return false;
      }
      return this.lastMinuteFreeCancellationLimit >= this.lastMinuteStart;
    },
    inconsistentFinalPaymentLimit() {
      if (
        isEmpty(this.newPolicy.partialRefundLimitInput) ||
        isEmpty(this.newPolicy.partialRefundAmount) ||
        isEmpty(this.newPolicy.initialPaymentAmount) ||
        isEmpty(this.newPolicy.finalPaymentLimitInput)
      ) {
        return false;
      }
      return (
        this.finalPaymentLimit < this.partialRefundLimit &&
        this.newPolicy.partialRefundAmount > this.newPolicy.initialPaymentAmount
      );
    },
    newPolicyPreview() {
      return {
        name: this.newPolicy.name,
        description: this.newPolicy.description,
        defaultPolicy: this.newPolicy.defaultPolicy,
        freeCancellationGracePeriod: this.freeCancellationGracePeriod,
        freeCancellationLimit: this.freeCancellationLimit,
        partialRefundLimit: this.partialRefundLimit,
        partialRefundAmount: this.newPolicy.partialRefundAmount,
        refundableServices: this.newPolicy.refundableServices,
        initialPaymentAmount: this.newPolicy.initialPaymentAmount,
        finalPaymentLimit: this.finalPaymentLimit,
        lastMinuteStart: this.lastMinuteStart,
        lastMinuteFreeCancellationLimit: this.lastMinuteFreeCancellationLimit,
        lastMinuteRefundableServices:
          this.newPolicy.lastMinuteRefundableServices,
      };
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    "newPolicy.partialRefundAmount": function (amount) {
      this.newPolicy.partialRefundAmount = amount ? amount * 1 : null;
    },
    // eslint-disable-next-line func-names
    "newPolicy.initialPaymentAmount": function (amount) {
      this.newPolicy.initialPaymentAmount = amount ? amount * 1 : null;
    },
  },
  created() {
    if (this.policy) this.initForm();
  },
  methods: {
    initForm() {
      this.newPolicy = {
        name: this.policy.name,
        description: this.policy.description,
        defaultPolicy: this.policy.defaultPolicy,
        freeCancellationGracePeriodInput: !this.policy.freeCancellationLimit
          ? this.convertedHoursValue(this.policy.freeCancellationGracePeriod)
          : null,
        freeCancellationGracePeriodUnitInput: !this.policy.freeCancellationLimit
          ? this.convertedHoursUnit(this.policy.freeCancellationGracePeriod)
          : "hours",
        freeCancellationLimitInput: this.convertedHoursValue(
          this.policy.freeCancellationLimit
        ),
        freeCancellationLimitUnitInput: this.convertedHoursUnit(
          this.policy.freeCancellationLimit
        ),
        partialRefundLimitInput: this.convertedHoursValue(
          this.policy.partialRefundLimit
        ),
        partialRefundLimitUnitInput: this.convertedHoursUnit(
          this.policy.partialRefundLimit
        ),
        partialRefundAmount: this.policy.partialRefundAmount,
        refundableServices: this.policy.refundableServices,
        initialPaymentAmount: this.policy.initialPaymentAmount,
        finalPaymentLimitInput: this.convertedHoursValue(
          this.policy.finalPaymentLimit
        ),
        finalPaymentLimitUnitInput: this.convertedHoursUnit(
          this.policy.finalPaymentLimit
        ),
        lastMinuteStartInput: this.convertedHoursValue(
          this.policy.lastMinuteStart
        ),
        lastMinuteStartUnitInput: this.convertedHoursUnit(
          this.policy.lastMinuteStart
        ),
        lastMinuteFreeCancellationLimitInput: this.convertedHoursValue(
          this.policy.lastMinuteFreeCancellationLimit
        ),
        lastMinuteFreeCancellationLimitUnitInput: this.convertedHoursUnit(
          this.policy.lastMinuteFreeCancellationLimit
        ),
        lastMinuteRefundableServices: this.policy.lastMinuteRefundableServices,
      };
    },
    resetForm() {
      this.newPolicy = {
        name: null,
        description: null,
        defaultPolicy: false,
        freeCancellationGracePeriodInput: null,
        freeCancellationGracePeriodUnitInput: "hours",
        freeCancellationLimitInput: null,
        freeCancellationLimitUnitInput: "days",
        partialRefundLimitInput: null,
        partialRefundLimitUnitInput: "days",
        partialRefundAmount: null,
        refundableServices: true,
        initialPaymentAmount: null,
        finalPaymentLimitInput: null,
        finalPaymentLimitUnitInput: "days",
        lastMinuteStartInput: null,
        lastMinuteStartUnitInput: "days",
        lastMinuteFreeCancellationLimitInput: null,
        lastMinuteFreeCancellationLimitUnitInput: "days",
        lastMinuteRefundableServices: true,
      };
    },
    cancel() {
      this.resetForm();
      this.$emit("cancel");
    },
    save() {
      if (this.policy) this.updatePolicy();
      else this.createPolicy();
    },
    createPolicy() {
      this.$store.dispatch("app/setLoading", true);
      setTimeout(() => {
        this.$store
          .dispatch("bookingPolicies/addPolicy", this.newPolicyPreview)
          .then((policy) => {
            // Policy added, if the new policy is marked as default policy,
            // we must update the rest of the policies as a no default.
            if (this.newPolicy.defaultPolicy) {
              this.$store
                .dispatch("bookingPolicies/updateDefaultPolicy", policy.uuid)
                .then(() => this.$emit("policy-added"))
                .catch(() => {
                  this.$emit("add-policy-error");
                  // TODO: log error with Sentry
                })
                .finally(() => {
                  this.resetForm();
                  this.$store.dispatch("app/setLoading", false);
                });
            } else {
              this.$emit("policy-added");
            }
          })
          .catch(() => {
            this.$emit("add-policy-error");
            // TODO: Log error in Sentry? Only if is not logged in the store
          })
          .finally(() => this.$store.dispatch("app/setLoading", false));
      }, 100);
    },
    updatePolicy() {
      this.$store.dispatch("app/setLoading", true);
      setTimeout(
        () => {
          this.$store
            .dispatch("bookingPolicies/updatePolicy", {
              uuid: this.policy.uuid,
              ...this.newPolicyPreview,
            })
            .then((policy) => {
              // Policy edited, if the new policy is marked as default policy,
              // we must update the rest of the policies as a no default.
              if (this.newPolicyPreview.defaultPolicy) {
                this.$store
                  .dispatch("bookingPolicies/updateDefaultPolicy", policy.uuid)
                  .then(() => this.$emit("policy-edited"))
                  .catch(() => {
                    this.$emit("add-policy-error");
                    // TODO: log error with Sentry
                  })
                  .finally(() => {
                    this.resetForm();
                    this.$store.dispatch("app/setLoading", false);
                  });
              } else {
                this.$emit("policy-edited");
              }
            })
            .catch(() => {
              this.$emit("edit-policy-error");
              // TODO: Log error in Sentry? Only if is not logged in the store
            })
            .finally(() => this.$store.dispatch("app/setLoading", false));
        },
        100,
        this
      );
    },
    convertedHoursValue(hours) {
      if (!hours) return null;
      return hours > 48 ? hours / 24 : hours.valueOf();
    },
    convertedHoursUnit(hours) {
      if (!hours) return "days";
      return hours > 48 ? "days" : "hours";
    },
    isEmpty(value) {
      return isEmpty(value);
    },
  },
};
</script>

<style lang="scss">
.alert {
  p {
    font-weight: normal;
  }
}
</style>
