<template>
  <b-card>
    <!-- BOOKING POLICIES TABLE -->
    <b-static-table
      :items="mappedPolicies"
      :fields="policiesTableFields"
      :loading="loading"
      :button-text="$t('Nova política')"
      show-button
      show-search
      show-actions
      @button-click="onNewPolicyButtonClicked"
      @item-click="onPolicyClicked"
      @item-edit-click="onEditPolicyClicked"
      @item-delete-click="onDeletePolicyClicked"
    />

    <!-- ADD POLICY MODAL -->
    <b-modal
      :id="'add-policy-modal'"
      title="Afegeix una nova política"
      scrollable
      hide-footer
    >
      <booking-policy-form
        @cancel="onAddPolicyCancel"
        @policy-added="onPolicyAdded"
        @add-policy-error="onAddPolicyError"
      />
    </b-modal>

    <!-- VIEW POLICY MODAL -->
    <b-modal
      :id="'view-policy-modal'"
      :title="`Política ${selectedPolicy ? selectedPolicy.name : null}`"
      scrollable
      hide-footer
    >
      <booking-policy-card
        :policy="selectedPolicy"
        @close="onClosePolicyDetails"
      />
    </b-modal>

    <!-- EDIT POLICY MODAL -->
    <b-modal
      :id="'edit-policy-modal'"
      title="Edita la política"
      scrollable
      hide-footer
    >
      <booking-policy-form
        :policy="selectedPolicy"
        @cancel="onEditPolicyCancel"
        @policy-edited="onPolicyEdited"
        @edit-policy-error="onEditPolicyError"
      />
    </b-modal>
  </b-card>
</template>

<script>
import BStaticTable from "@foravila-core/components/b-static-table/BStaticTable.vue";
import BookingPolicyForm from "@/views/booking-policies/list/components/BookingPolicyForm.vue";
import BookingPolicyCard from "@/views/booking-policies/list/components/BookingPolicyCard.vue";
import { getHoursText, notifyError, notifySuccess } from "@/utils/methods";
import { BCard } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BStaticTable,
    BookingPolicyForm,
    BookingPolicyCard,
  },
  data() {
    return {
      selectedPolicy: null,
      policiesTableFields: [
        { key: "name", label: this.$t("Nom"), sortable: true },
        {
          key: "finalPaymentLimit",
          label: this.$t("Límit pagament final"),
          sortable: true,
        },
        {
          key: "freeCancellationLimit",
          label: this.$t("Límit cancel·lació gratuïta"),
          sortable: true,
        },
        {
          key: "partialRefundLimit",
          label: this.$t("Límit cancel·lació parcial"),
          sortable: true,
        },
        {
          key: "partialRefundAmount",
          label: this.$t("Devolució parcial"),
          sortable: true,
        },
        {
          key: "actions",
          label: this.$t("Accions"),
          thStyle: { width: "95px" },
        },
      ],
    };
  },
  computed: {
    loading() {
      return this.$store.getters["bookingPolicies/loading"];
    },
    policies() {
      return this.$store.getters["bookingPolicies/policies"];
    },
    mappedPolicies() {
      return this.policies.map((policy) => ({
        uuid: policy.uuid || "No definit",
        name: policy.name || "No definit",
        description: policy.description || "No definit",
        defaultPolicy: policy.defaultPolicy || false,
        finalPaymentLimit:
          getHoursText(policy.finalPaymentLimit) || "No definit",
        freeCancellationLimit:
          getHoursText(policy.freeCancellationLimit) || "No definit",
        partialRefundLimit:
          getHoursText(policy.partialRefundLimit) || "No definit",
        partialRefundAmount: policy.partialRefundAmount
          ? `${policy.partialRefundAmount}%`
          : "No definit",
      }));
    },
  },
  created() {
    if (this.policies.length === 0 && !this.loading) this.fetchPolicies();
  },
  methods: {
    fetchPolicies() {
      this.$store
        .dispatch("bookingPolicies/fetchPolicies", {
          pagination: false,
          sort: "asc",
        })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchPolicies.title"),
            this.$t("errors.fetchPolicies.description")
          )
        );
    },
    onNewPolicyButtonClicked() {
      this.$bvModal.show("add-policy-modal");
    },
    onPolicyClicked(policyUuid) {
      this.selectedPolicy = this.policies.find((p) => p.uuid === policyUuid);
      this.$bvModal.show("view-policy-modal");
    },
    onEditPolicyClicked(policyUuid) {
      this.selectedPolicy = this.policies.find((p) => p.uuid === policyUuid);
      this.$bvModal.show("edit-policy-modal");
    },
    onDeletePolicyClicked(policyUuid) {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("bookingPolicies/deletePolicy", policyUuid)
        .then(() => {
          this.fetchPolicies();
          notifySuccess(
            "Política eliminada",
            "La política ha estat eliminada correctament"
          );
        })
        .catch(() => {
          notifyError(
            "Error",
            "Hi ha hagut un error al intentar eliminar la política"
          );
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    onAddPolicyCancel() {
      this.$bvModal.hide("add-policy-modal");
    },
    onPolicyAdded() {
      this.fetchPolicies();
      this.$bvModal.hide("add-policy-modal");
      notifySuccess(
        "Política afegida",
        "La política ha estat afegida correctament"
      );
    },
    onAddPolicyError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar afegir la política"
      );
    },
    onEditPolicyCancel() {
      this.$bvModal.hide("edit-policy-modal");
    },
    onPolicyEdited() {
      this.fetchPolicies();
      this.$bvModal.hide("edit-policy-modal");
      notifySuccess(
        "Política editada",
        "La política ha estat editada correctament"
      );
    },
    onEditPolicyError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar editar la política"
      );
    },
    onClosePolicyDetails() {
      this.$bvModal.hide("view-policy-modal");
    },
  },
};
</script>
